<template>
  <b-row>
    <b-col cols="12">
      <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
        <div>
          <h2 class="font-weight-bolder m-0 hGoBack">
            <span @click="$router.go(-1)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
            </span> 
            {{ $t('orders.order_status') }}
          </h2>
        </div>
      </div>
      <b-row v-if="orderPrepare">
        <b-col cols="12">
          <b-card class="height-card px-2">
            <div class="text-right">
              <span v-if="currentUser.roles[0].name === 'super_admin'">
                <b-link :to="{ name: 'editOrders', params: { id: order.id } }" class="btn-primary mr-1">
                  <span><feather-icon icon="Edit2Icon" />{{ $t('Editar') }}</span>
                </b-link>
              </span>
              <span v-else>
                <button v-if="order.status_last.type === 'pending'"
                  class="btn btn-primary" @click="goToOrder(order.id)" type="button">
                  <feather-icon icon="Edit2Icon" /> 
                  {{ $t('Editar') }}
              </button>
              </span>
            </div>
            <hr>
            <b-row class="position-relative justify-content-center">
              <b-col cols="12" class="m-1 seguimiento p-2">
                <div>
                  <span class="size-24 pb-3">{{ $t('orders.follow_up') }}</span>
                </div>
                <b-row class="mt-2">
                  <b-col v-if="!ready">
                    <b-row>
                      <b-col>
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <feather-icon icon="EditIcon" size="18" class="mr-50 icon-status" />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.pending') }}</span></strong></div>
                            <span v-if="pending">{{ pending }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col v-if="reviewCheck">
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <iconReview />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.revision') }}</span></strong></div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col :class="{ grey_status: !budgetCheck }">
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <feather-icon icon="FileTextIcon" size="18" class="mr-50 icon-status" />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.budget') }}</span></strong></div>
                            <span v-if="budget && budgetCheck">{{ budget }}</span>
                          </b-col>
                        </b-row>

                      </b-col>
                      <b-col :class="{ grey_status: !budget_adminCheck }">
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <feather-icon icon="FileTextIcon" size="18" class="mr-50 icon-status" />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.budget_admin') }}</span></strong></div>
                            <span v-if="budget_admin && budget_adminCheck">{{ budget_admin }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col v-else>
                    <b-row>
                      <b-col md=6 lg=6 xl="3" class="mb-1" :class="{ grey_status: !readyCheck }">
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <iconPreparacion class="icon-status" />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.ready') }}</span></strong></div>
                            <span v-if="ready && readyCheck">{{ ready }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md=6 lg=6 xl="3" class="mb-1" :class="{ grey_status: !sendCheck }">
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <iconEnviado class="icon-status" />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.send') }}</span></strong></div>
                            <span v-if="send && sendCheck">{{ send }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md=6 lg=6 xl="3" class="mb-1" :class="{ grey_status: !castCheck }">
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <iconReparto class="icon-status" />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.cast') }}</span></strong></div>
                            <span v-if="cast && castCheck">{{ cast }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md=6 lg=6 xl="3" class="mb-1" :class="{ grey_status: !deliveredCheck }">
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <iconEntregado class="icon-status-entregado" />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.delivered') }}</span></strong></div>
                            <span v-if="delivered && deliveredCheck">{{ delivered }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3" class="text-right">
                    <b-button 
                      v-if="order.status_last['type'] == 'budget'"
                      class="mr-1" 
                      @click="changeStatusFunction('budget_admin')"
                    >
                      {{ $t('orders.accept_quote') }}
                    </b-button>
                    <b-button
                      v-else-if="order.status_last['type'] == 'budget_admin' && currentUser.roles[0].name === 'super_admin'"
                      class="mr-1" 
                      @click="changeStatusFunction('ready')"
                    >
                      {{ $t('orders.accept_order') }}
                    </b-button>
                    <b-button v-if="order.status_last['type'] == 'budget_admin' && currentUser.roles[0].name !== 'super_admin'" target="_blank" :href="validateURL(url)" :disabled="disabledLocation">
                      {{ $t('orders.locate_shipment') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" class="m-1 p-2">
                <!-- datos -->
                <b-row>
                  <b-col>
                    <div><strong><span>{{ $t('orders.order_number') }} :</span></strong></div>
                    <span>{{ dateColumnYear(order.created_at) }}/T{{ order.id }}</span>
                  </b-col>
                  <b-col>
                    <div><strong><span>{{ $t('orders.order_date') }} :</span></strong></div>
                    <span>{{ dateColumn(order.created_at) }}</span>
                  </b-col>
                  <b-col>
                    <div><strong><span>{{ $t('orders.updated_at') }} :</span></strong></div>
                    <span>{{ dateColumn(order.updated_at) }}</span>
                  </b-col>
                  <b-col v-if="currentUser.roles[0].name !== 'user'">
                    <div><strong><span>{{ $t('orders.client') }}:</span></strong></div>
                    <span>{{ order.client.name }}</span>
                  </b-col>
                  <b-col>
                    <div><strong><span>{{ $t('orders.responsible') }}:</span></strong></div>
                    <span>{{ order.user.email }}</span>
                  </b-col>
                </b-row>
                <hr>
                <!-- ficheros -->
                <b-row class="mt-2">
                  <b-col>
                    <b-link v-if="order.budget" :href="order.budget.path" target="_blank" class="d-inline-block px-50">
                      <span>{{ $t('orders.download_quote') }}</span>
                    </b-link>
                  </b-col>
                  <b-col>
                    <b-link v-if="order.delivery_note" :href="order.delivery_note.path" target="_blank"
                      class="d-inline-block px-50">
                      {{ $t('orders.see_delivery_note') }}
                    </b-link>
                  </b-col>
                  <b-col>
                    <b-link v-if="order.invoice" :href="order.invoice.path" target="_blank" class="d-inline-block px-50">
                      {{ $t('orders.see_invoice') }}
                    </b-link>
                  </b-col>
                  <b-col class="text-right" v-if="currentUser.roles[0].name === 'super_admin'">
                    <UploadPDF :id-order="order.id" />
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" class="p-0">
                <b-row>
                  <Tables :lines-cortinas="linesCortinas" :lines-cuadros="linesCuadros" :lines-extras="linesExtras"
                    :order="order" />
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { TimestampToFlatPickr, TimestampToFlatPickrOnlyYear } from '@/libs/helpers'
import { config } from '@/shared/app.config'
import iconPreparacion from '@/assets/images/status/preparacion'
import iconEnviado from '@/assets/images/status/enviado'
import iconReparto from '@/assets/images/status/reparto'
import iconEntregado from '@/assets/images/status/entregado'
import iconReview from '@/assets/images/status/review'
import Tables from './Tables'
import UploadPDF from './Upload'
import { email } from '@validations';

export default {
  components: {
    BButton,
    BLink,
    BRow,
    BCol,
    BCard,
    iconPreparacion,
    iconEnviado,
    iconReparto,
    iconEntregado,
    Tables,
    iconReview,
    UploadPDF
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusOrderVariant: config.statusOrderVariants,
      dir: false,
      orderPrepare: false,
      ready: false,
      send: false,
      cast: false,
      delivered: false,
      budget: false,
      budget_admin: false,
      pending: false,
      review: false,
      url: '',
      disabledLocation: true,
      readyCheck: false,
      sendCheck: false,
      castCheck: false,
      deliveredCheck: false,
      budgetCheck: false,
      budget_adminCheck: false,
      pendingCheck: false,
      reviewCheck: false,
    }
  },
  computed: {
    ...mapGetters({
      order: 'orders/getOrder',
      currentUser: 'auth/getUser',
    }),
    linesCortinas() {
      const lines = []
      if (this.order) {
        this.order.lines.forEach(e => {
          const attr = JSON.parse(e.atributos)
          if (attr.tipo && attr.tipo === 'cortinas') {
            e.attr = attr
            lines.push(e)
          }
        })
      }
      return lines
    },
    linesCuadros() {
      const lines = []
      if (this.order) {
        this.order.lines.forEach(e => {
          const attr = JSON.parse(e.atributos)
          if (attr.tipo && attr.tipo === 'cuadro control') {
            e.attr = attr
            lines.push(e)
          }
        })
      }
      return lines
    },
    linesExtras() {
      const lines = []
      if (this.order) {
        this.order.lines.forEach(e => {
          const attr = JSON.parse(e.atributos)
          if (attr.tipo && attr.tipo === 'extras') {
            e.attr = attr
            lines.push(e)
          }
        })
      }
      return lines
    },
  },
  methods: {
    ...mapActions({
      getOrder: 'orders/getOrder',
      changeStatus: 'orders/changeStatusOrder',
      changeOrder: 'steps/getOrder',
      setStep: 'steps/setStep',

    }),
    validateURL(url) {
      if (/(http(s?)):\/\//i.test(url)) {
        // do something here
        return url
      }
      return '//' + url
    },
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    dateColumnYear(time) {
      return TimestampToFlatPickrOnlyYear(time)
    },
    chargeData() {
      if (this.order) {
        this.order.status.forEach(e => {
          switch (e.type) {
            case 'pending':
              this.pending = this.dateColumn(e.created)
              break
            case 'revision':
              this.review = this.dateColumn(e.created)
              break
            case 'budget':
              this.budget = this.dateColumn(e.created)
              break
            case 'budget_admin':
              this.budget_admin = this.dateColumn(e.created)
              break
            case 'ready':
              this.ready = this.dateColumn(e.created)
              if (e.url_seguimiento) {
                this.url = e.url_seguimiento
              }
              break
            case 'send':
              this.send = this.dateColumn(e.created)
              if (e.url_seguimiento) {
                this.url = e.url_seguimiento
              }
              break
            case 'cast':
              this.cast = this.dateColumn(e.created)
              if (e.url_seguimiento) {
                this.url = e.url_seguimiento
              }
              break
            case 'delivered':
              this.delivered = this.dateColumn(e.created)
              if (e.url_seguimiento) {
                this.url = e.url_seguimiento
              }
              break
          }
          // console.log(this.url)
        })
        if (this.order.status_last) {
          switch (this.order.status_last.type) {
            case 'pending':
              this.pendingCheck = true
              break
            case 'revision':
              this.pendingCheck = true
              this.reviewCheck = true
              break
            case 'budget':
              if(this.order.status.some(({ type }) => type === 'revision')) {
                this.reviewCheck = true
              }
              this.pendingCheck = true
              this.budgetCheck = true
              break
            case 'budget_admin':
              if(this.order.status.some(({ type }) => type === 'revision')) {
                this.reviewCheck = true
              }
              this.pendingCheck = true
              this.budgetCheck = true
              this.budget_adminCheck = true
              break
            case 'ready':
              if(this.order.status.some(({ type }) => type === 'revision')) {
                this.reviewCheck = true
              }
              this.pendingCheck = true
              this.budgetCheck = true
              this.budget_adminCheck = true
              this.readyCheck = true
              break
            case 'send':
              if(this.order.status.some(({ type }) => type === 'revision')) {
                this.reviewCheck = true
              }
              this.pendingCheck = true
              this.budgetCheck = true
              this.budget_adminCheck = true
              this.readyCheck = true
              this.sendCheck = true
              break
            case 'cast':
              if(this.order.status.some(({ type }) => type === 'revision')) {
                this.reviewCheck = true
              }
              this.pendingCheck = true
              this.budgetCheck = true
              this.budget_adminCheck = true
              this.readyCheck = true
              this.sendCheck = true
              this.castCheck = true
              break
            case 'delivered':
              if(this.order.status.some(({ type }) => type === 'revision')) {
                this.reviewCheck = true
              }
              this.pendingCheck = true
              this.budgetCheck = true
              this.budget_adminCheck = true
              this.readyCheck = true
              this.sendCheck = true
              this.castCheck = true
              this.deliveredCheck = true
              break
          }
        }
        this.orderPrepare = true
        this.disabledLocation = false;
        if (this.url === '' || this.url === null) this.disabledLocation = true;
      }
    },
    changeStatusFunction(status) {
      this.changeStatus({
        id: this.$route.params.id,
        status,
        planned_delivery_date: '',
      })
    },
    async goToOrder(id) {
      // localStorage.removeItem('order')
      await this.changeOrder(id)
      await this.$store.commit('steps/setLinesCuadro', [])
      await this.setStep(4)
      await this.$router.push({ name: 'steps' })
    },

  },
  async created() {
    await this.getOrder(this.$route.params.id)
    await this.chargeData()
  },
}
</script>
